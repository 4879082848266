.react-material-final-form .form-radio-container > legend {
    font-size: 1rem !important;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: left top;
}

.react-material-final-form .form-field-container {
    margin-top: 10px!important;
}

.react-material-final-form .form-field-container .date-field {
    margin-top: 0px!important;
}

.react-material-final-form .nested-form-input-field {
    margin-top: 10px!important;
}
